const ParticleConfig = {
  particles: {
      number: {
          value: 50,
          density: {
              enable: true,
              value_area: 880.951427541745
          }
      },
      color: {
          value: "#2f7c87"
      },
      shape: {
          type: "circle",
          stroke: {
              width: 0.4,
              color: "#3aafc0"
          },
          polygon: {
              nb_sides: 5
          },
          image: {
              src: "img/github.svg",
              width: 100,
              height: 100
          }
      },
      opacity: {
          value: 0.5,
          random: false,
          anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
          }
      },
      size: {
          value: 5,
          random: true,
          anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false
          }
      },
      line_linked: {
          enable: true,
          distance: 128.13838946061745,
          color: "#ffffff",
          opacity: 0.20822488287350338,
          width: 0.9610379209546309
      },
      move: {
          enable: true,
          speed: 9.61037920954631,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "bounce",
          bounce: false,
          attract: {
              enable: false,
              rotateX: 720.7784407159731,
              rotateY: 1200
          }
      }
  },
  interactivity: {
      detect_on: "canvas",
      events: {
          onhover: {
              enable: true,
              mode: "repulse"
          },
          onclick: {
              enable: true,
              mode: "push"
          },
          resize: true
      },
      modes: {
          grab: {
              distance: 400,
              line_linked: {
                  opacity: 1
              }
          },
          bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
          },
          repulse: {
              distance: 200,
              duration: 0.4
          },
          push: {
              particles_nb: 4
          },
          remove: {
              particles_nb: 2
          }
      }
  },
  retina_detect: true
};

export default ParticleConfig;