import React from "react";
import './AlgoMenu.css'
import {Container, Col, Image, Row} from 'react-bootstrap'

export default function AlgoMenu (){
    return(
        <div className="algomenu">
            <h1>Hello World!</h1>
        </div>
    )
}